<template>
  <v-snackbar
    v-model="active"
    class="snackbar"
    :color="type"
    location="top right"
    timeout="8000"
  >
    {{ text }}
    <template #actions>
      <v-btn
        icon
        :data-cy="'snackbar-' + type"
        @click.stop="active = false"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useNotificationStore } from '~/store/notification'

export default {
  computed: {
    ...mapState(useNotificationStore, ['type', 'text']),
    ...mapWritableState(useNotificationStore, ['active']),
  },
}
</script>
